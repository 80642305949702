<template>
  <v-container>
    <v-alert
      outline
      type="error"
      :value="error !== null"
      style="font-size: 13px"
      transition="slide-y"
      >{{ error }}</v-alert
    >
    <v-form v-model="hasError" @submit.prevent="login" id="check-login-form">
    </v-form>

    <v-layout>
      <v-spacer />
      <v-btn
        type="submit"
        color="orange darken-1"
        :disabled="loading"
        :loading="loading"
        class="px-4 white--text"
        form="check-login-form"
      >
        Login With Google
        <img class="icon-size" src="../../assets/google.png" />
      </v-btn>
      <v-spacer />
    </v-layout>
  </v-container>
</template>

<script>
import { StorageKeys, WorkingCountries } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
export default {
  data() {
    return {
      isSignIn: false,
      email: null,
      password: null,
      loading: true,
      error: null,
    };
  },
  mounted() {
    setTimeout(() => (this.loading = false), 3000);
  },
  computed: {
    hasError: {
      get() {
        return this.email === null || this.password === null;
      },
      set(val) {},
    },
  },
  methods: {
    login() {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.loading = true;
      this.error = null;
      this.$gAuth
        .signIn()
        .then((user) => {
          this.loading = true;
          let googleData = user.getBasicProfile();
          // On success do something, refer to https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
          var domain = googleData
            .getEmail()
            .substring(googleData.getEmail().lastIndexOf("@") + 1);

          // if (domain != "trukkin.com" && domain != "volumetree.com") {
          //   this.setError(
          //     "You can not login with this account as you are not member of Trukkin."
          //   );
          //   this.loading = false;
          //   return;
          // }
          let webToken = localStorage.getItem("webToken");
          let obj = {};
          obj = {
            email: googleData.getEmail(),
            id: googleData.getId(),
            name: googleData.getName(),
          };
          if (webToken) {
            obj["webToken"] = webToken;
          }
          this.$http.post(`${apiUrl}/dmsAdmin/socialLogin`, obj).then(
            (response) => {
              const { statusCode, data } = response.data;
              if (statusCode) {
                const { accessToken } = data;
                localStorage.setItem("user_Id", data._id);
                localStorage.setItem(StorageKeys.SessionKey, accessToken);
                localStorage.setItem("userId", data.userId);
                localStorage.setItem("activeMenu", 2);
                localStorage.setItem("user", data.name);
                localStorage.setItem("role", data.roleType);
                localStorage.setItem("email", googleData.getEmail());

                this.identify(googleData.getEmail());
                this.setPeople({
                  $email: googleData.getEmail(),
                  $name: googleData.getName(),
                });
                this.track("Login", {
                  $email: googleData.getEmail(),
                  $name: googleData.getName(),
                });

                // ----------------- SETTING MASTER COUNTRIES FOR PLATFORM
                if (data.countrySortName.length) {
                  let accessibleCountries = [];
                  WorkingCountries.forEach((key, index) => {
                    if (data.countrySortName.indexOf(key.value) >= 0) {
                      accessibleCountries.push(key);
                    }
                  });
                  if (accessibleCountries.length) {
                    accessibleCountries[0].isSelected = true;
                    if (accessibleCountries[1]) {
                      accessibleCountries[1].isSelected = false;
                    }
                    if (accessibleCountries[2]) {
                      accessibleCountries[2].isSelected = false;
                    }
                  }
                  localStorage.setItem(
                    "workingCountries",
                    JSON.stringify(accessibleCountries)
                  );
                  EventBus.$emit("setCountries", accessibleCountries);
                } else {
                  this.setError(
                    "You do not have access to any country! Please contact admin"
                  );
                  return false;
                  this.loading = false;
                }

                EventBus.$emit("User-Id", data.userId);
                this.$http
                  .post(`${rbacUrl}/checkUserPermission`, {
                    userId: localStorage.getItem("userId"),
                  })
                  .then((res) => {
                    localStorage.setItem(
                      "permissions",
                      JSON.stringify(res.data.data)
                    );
                    if (!res.data.data) {
                      this.loading = false;
                    }
                    let router = this.$router;
                    this.loading = false;
                    localStorage.setItem("first-load", true);
                    this.$router.replace("/");
                    EventBus.$emit("logged-in-success", data);
                  })
                  .catch((e) => {
                    localStorage.removeItem("userId");
                    localStorage.removeItem("user");
                    localStorage.removeItem("role");
                    localStorage.removeItem("email");
                    localStorage.removeItem(StorageKeys.SessionKey);
                    localStorage.removeItem("workingCountries");
                    this.setError(
                      "Failed to login" +
                        (this.data && this.data.message
                          ? ":" + this.data.message
                          : "")
                    );
                    this.loading = false;
                  });
              } else {
                localStorage.removeItem(StorageKeys.SessionKey);
                localStorage.removeItem("userId");
                localStorage.removeItem("user");
                localStorage.removeItem("role");
                localStorage.removeItem("email");
                localStorage.removeItem("workingCountries");
                this.setError(
                  "Failed to login" +
                    (this.data && this.data.message
                      ? ":" + this.data.message
                      : "")
                );
                this.loading = false;
              }
            },
            (error) => {
              this.setError(error.response.data.message);

              this.loading = false;
            }
          );

          this.isSignIn = this.$gAuth.isAuthorized;
        })
        .catch((error) => {
          this.loading = false;
          this.setError("Google login failed");
        });
    },
    setError(error) {
      this.error = error;
      setTimeout(() => (this.error = null), 5000);
    },
  },
};
</script>

<style>
.icon-size {
  width: 16px;
  margin-left: 10px;
}
</style>
