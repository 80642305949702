<template>
  <v-container centered>
    <v-card color="white" style="max-width: 600px; margin: auto">
      <v-card-text class="card-style m-t-10">
        <v-layout row class="mt-20 mb-2">
          <v-spacer />
          <img
            src="../../assets/v_logo_new.png"
            class="logo"
            width="234"
            height="90"
            style="margin-top:20px;"
          />
          <v-spacer />
        </v-layout>
        <v-layout column class="pt-0 px-3">
          <v-flex class="subheading muted">Welcome to</v-flex>
          <v-flex class="heading theme-text">Trukkin Admin Dashboard</v-flex>
        </v-layout>
        <auth-login />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AuthLogin from "@/components/Auth/AuthLogin";
export default {
  created() {
    this.$eventBus.$emit("show-nav-items", false);
  },
  components: {
    AuthLogin,
  },
  data() {
    return {
      active: "tab-1",
    };
  },
  methods: {
    next(active) {
      this.active = active;
    },
  },
  destroyed() {
    this.$eventBus.$emit("show-nav-items", true);
  },
};
</script>

<style scoped>
.logo {
  padding: 10px;
}
.mt-20 {
  margin-top: 20px !important;
}
.pt-0 {
  padding: 0 !important;
}
.m-t-10 {
  margin-top: 10% !important;
}
</style>
